<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-input
              v-decorator="['iccid_or_phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID号或手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="服务周期结束月">
            <a-month-picker
              style="width: 100%;"
              :disabled-date="disabledDate"
              v-decorator="['service_end_date_month']"
              placeholder="请选择月份"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="联网状态">
            <a-select
              v-decorator="['net_status']"
              allow-clear
              @focus="fetchNetStatusOptions"
              :loading="loadingNetStatusOptions"
            >
              <a-select-option
                v-for="option in netStatusOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="用户业务模式">
            <a-select
              v-decorator="['user_business_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in userBusinessTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import {
  findUserBusinessTypeOptions,
  findNetStatusOptions,
  findCarrierTypeOptions
} from '@/api/common_options'
import { formatYearMonth } from '@/utils/time'
export default {
  name: 'SearchAgentServiceCycleUnexpiredSimCard',
  props: {
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false,
      carrierTypeOptions: findCarrierTypeOptions(),
      netStatusOptions: [],
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      loadingNetStatusOptions: false,
      form: this.$form.createForm(this, { name: 'service_cycle_unexpired_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    disabledDate(current) {
      // 只能选择当月以及将来的月份
      return current && current.endOf('month') < this.$moment().endOf('month')
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    // 加载联网状态选项数据
    fetchNetStatusOptions() {
      if (this.netStatusOptions.length === 0) {
        this.loadingNetStatusOptions = true
        findNetStatusOptions().then(res => {
          if (res.code === 0) {
            this.netStatusOptions = res.data
          }
          this.loadingNetStatusOptions = false
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        service_end_date_month: formatYearMonth(fieldsValue.service_end_date_month)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      // 清空选项
      this.carrierTypeOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
