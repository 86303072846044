// 处理时间范围控件时间格式 moment 数组中开始时间 转为 字符串
export function formatRangeBeginDate(timeArray) {
  if (timeArray && timeArray.length > 0) {
    return timeArray[0].format('YYYY-MM-DD')
  } else {
    return ''
  }
}

// 处理时间范围控件时间格式 moment 数组中结束时间 转为 字符串
export function formatRangeEndDate(timeArray) {
  if (timeArray && timeArray.length === 2) {
    return timeArray[1].format('YYYY-MM-DD')
  } else {
    return ''
  }
}

// 处理时间范围控件时间格式 moment 数组中开始时间 转为 字符串
export function formatRangeBeginDateTime(timeArray) {
  if (timeArray && timeArray.length > 0) {
    return timeArray[0].format('YYYY-MM-DD HH:mm:ss')
  } else {
    return ''
  }
}

// 处理时间范围控件时间格式 moment 数组中结束时间 转为 字符串
export function formatRangeEndDateTime(timeArray) {
  if (timeArray && timeArray.length === 2) {
    return timeArray[1].format('YYYY-MM-DD HH:mm:ss')
  } else {
    return ''
  }
}

// 处理时间控件时间格式 moment 时间 转为 date 字符串
export function formatDate(time) {
  if (time) {
    return time.format('YYYY-MM-DD')
  } else {
    return ''
  }
}

// 处理时间控件时间格式 moment 时间 转为 格式为 202105 字符串
export function formatYearMonth(time) {
  if (time) {
    return time.format('YYYYMM')
  } else {
    return ''
  }
}

// 处理时间控件时间格式 moment 时间 转为 格式为 20210501 字符串
export function formatYearMonthDay(time) {
  if (time) {
    return time.format('YYYYMMDD')
  } else {
    return ''
  }
}
