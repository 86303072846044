import request from '@/utils/request'

// 获取服务周期即将到期的卡
export function findAgentServiceCycleUnexpiredSimCardsByCustomer(params) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/unexpired`,
    method: 'get',
    params: params
  })
}

// 导出服务周期未到期的卡
export function exportAgentServiceCycleUnexpiredSimCardsByCustomer(data) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/unexpired/export`,
    method: 'post',
    data
  })
}

// 获取服务周期已到期的卡
export function findAgentServiceCycleExpiredSimCardsByCustomer(params) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/expired`,
    method: 'get',
    params: params
  })
}

// 导出服务周期已到期的卡
export function exportAgentServiceCycleExpiredSimCardsByCustomer(data) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/expired/export`,
    method: 'post',
    data
  })
}

// 获取本月预计销卡列表
export function findAgentCurrentMonthExpectCancelSimCardsByCustomer(params) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/current_month_expect_cancel`,
    method: 'get',
    params: params
  })
}

// 导出本月预计销卡列表
export function exportAgentCurrentMonthExpectCancelSimCardsByCustomer(data) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/current_month_expect_cancel/export`,
    method: 'post',
    data
  })
}

// 获取已销卡列表
export function findAgentCanceledSimCardsByCustomer(params) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/canceled`,
    method: 'get',
    params: params
  })
}

// 导出本已销卡列表
export function exportAgentCanceledSimCardsByCustomer(data) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/canceled/export`,
    method: 'post',
    data
  })
}

// 获取续期记录
export function findAgentRenewedSimCards(params) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/renewed`,
    method: 'get',
    params: params
  })
}

// 获取激活记录
export function findAgentActivedSimCards(params) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/actived`,
    method: 'get',
    params: params
  })
}

// 导出续期记录
export function exportAgentRenewedSimCards(data) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/renewed/export`,
    method: 'post',
    data
  })
}

// 导出激活记录
export function exportAgentActivedSimCards(data) {
  return request({
    url: `/customer/sim_cards/service_cycle_manage/actived/export`,
    method: 'post',
    data
  })
}
